@keyframes appear-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

$appear-animate: appear-animate 200ms ease-in;
