@mixin app-theme($theme) {
  mpv-root {
    router-outlet + * {
      animation: $appear-animate;
    }

    oculus-footer {
      color: map-get($grays, 500);
    }
  }
}
