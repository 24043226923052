/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@include mat-core();

@import '~@oculus/styling/ellipse';
@import '~@oculus/styling/ellipse/colors';
@import '~@oculus/components/theming';

@import 'theming/animations';
@import 'theming/palette';
@import 'theming/theme';
@import 'theming/typography';
@import 'theming/helpers';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';

@import 'app/app.component.theming';
@import 'app/shared/components/generic-error/generic-error.component.theming';
@import 'app/shared/components/content-header/content-header.component.theming';
@import 'app/shared/components/section-header/section-header.component.theming';

/* Library Components Themes */
@include oculus-drawer-theme($mpv-theme);
@include oculus-header-theme($mpv-theme);

/* App Components Themes */
@include app-theme($mpv-theme);
@include generic-error-theme($mpv-theme);
@include content-header-theme($mpv-theme);
@include section-header-theme($mpv-theme);

/* Oculus */
.oculus-drawer-content {
  @include ellipse-layout-page();

  min-height: 100vh;
  height: auto !important;
}

/* Main */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Lato, monospace';
}

/* Multi-site P.V. Helpers */
.mpv-large-icon {
  height: 50px !important;
  width: 50px !important;
  font-size: 50px;
}

.mpv-clickable {
  color: #009de9;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
}

.mpv-full-width {
  width: 100% !important;
}

.mpv-full-max-width {
  max-width: 100% !important;
}

/* Material Overrides */
.mat-form-field-suffix {
  color: mat-color($mat-gray, 500);
}

.mat-button {
  text-transform: uppercase;
}

/* Oculus Overrides */
.oculus-drawer-header {
  .oculus-drawer-logo {
    padding: 1.8rem 1rem 0 0.5rem !important;
  }

  .oculus-drawer-logo-mini {
    padding: 1.8rem 0.3rem 0 !important;
    width: auto !important;
  }
}
