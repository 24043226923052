.mpv {
  $opacity: 0.06;
  $text: mat-color($mat-gray, 700);

  $orange: mat-color($mpv-warn, orange);
  $orange-contrast: $text;
  $orange-icon: mat-color($mpv-warn, orange);

  $red: mat-color($mpv-warn, red);
  $red-contrast: $text;
  $red-icon: mat-color($mpv-warn, red);

  $green: mat-color($mpv-warn, green);
  $green-contrast: $text;
  $green-icon: mat-color($mpv-warn, green);

  $gray: mat-color($mat-gray, 600);
  $gray-contrast: $text;
  $gray-icon: mat-color($mat-gray, 600);

  $dark-blue: mat-color($mpv-primary, darker);
  $dark-blue-contrast: $text;
  $dark-blue-icon: mat-color($mpv-primary, darker);

  &-error {
    color: $red;
  }

  &-warning {
    color: $orange;
  }
}
