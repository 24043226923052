@mixin content-header-theme($theme) {
  mpv-content-header {
    .mpv-content-description {
      color: map-get($grays, 600);
    }

    .mpv-content-status-success {
      mat-chip {
        background-color: green;
      }
    }
  }
}
